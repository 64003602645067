<template>
  <div class="faq-card">
    <div class="question-bar" @click="isCollapsed = !isCollapsed">
      <h3 class="question">
        {{ FAQ.question }}
      </h3>
      <div class="collapse-icon">
        <ion-icon
          v-if="!isCollapsed"
          name="chevron-up-outline"
          class="big-icon"
        ></ion-icon>
        <ion-icon
          v-if="isCollapsed"
          name="chevron-down-outline"
          class="big-icon"
        ></ion-icon>
      </div>
    </div>
    <div :class="{'answerContainerFolded': isCollapsed, 'answerContainer': !isCollapsed}">
      <h3 class="answer">
        {{ FAQ.answer }}
      </h3>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: ["FAQ"],
  setup() {
    const isCollapsed = ref(true);

    return { isCollapsed };
  },
};
</script>

<style scoped>
.faq-card {
  margin-bottom: 3vh;
}

h3 {
  font-weight: 100;
  font-size: 125%;
}

.question-bar {
  display: flex;
  align-items: center;
  color: white;
  background-color: var(--purple-l);
  padding: 2vh;
  transition: 0.3s ease-out;
}

.question-bar:hover {
  background-color: var(--purple);
  cursor: pointer;
}

.question {
  flex: 0 1 95%;
  font-weight: 800;
  padding-left: 1vh;
}

.collapse-icon {
  flex: 0 1 auto;
}

.answer {
  font-size: 110%;
  padding: 2vh;
  padding-left: 3vh;
}

.answerContainer {
  max-height: 30vh;
  transform-origin: top;
  transition: all 1s ease;
  overflow: hidden;
  background-color: white;
}

.answerContainerFolded {
  max-height: 0vh;
  transform-origin: top;
  transition: all 0.5s ease;
  overflow: hidden;
}

</style>
