<template>
  <div v-if="isLoaded" class="FAQ">
    <h2>Frequently Asked Questions</h2>
    <h3>General</h3>
    <FAQCard v-for="FAQ in FAQs[0]" :key="FAQ.id" :FAQ="FAQ" />
    <h3>WishList</h3>
    <FAQCard v-for="FAQ in FAQs[3]" :key="FAQ.id" :FAQ="FAQ" />
    <h3>Students</h3>
    <FAQCard v-for="FAQ in FAQs[1]" :key="FAQ.id" :FAQ="FAQ" />
    <h3>Alumni</h3>
    <FAQCard v-for="FAQ in FAQs[2]" :key="FAQ.id" :FAQ="FAQ" />
    
  </div>
</template>

<script>
import getFAQ from "@/composables/FAQ/getFAQ";
import { ref, onMounted } from "vue";
import FAQCard from "@/components/FAQ/FAQCard.vue";

export default {
  components: {
    FAQCard,
  },
  async setup() {
    const FAQs = ref(null);
    const isLoaded = ref(false);

    onMounted(async () => {
      await getFAQ(FAQs);
      isLoaded.value = true;
    });

    return { isLoaded, FAQs };
  },
};
</script>

<style scoped>
.FAQ {
  flex: 1 0 80%;
  padding: 3vh 8vh;
  animation: fadein 1s;
}

@media screen and (max-width: 600px) {
  .FAQ {
    padding: 3vh 2vh;
  }
}

h2 {
  font-weight: 100;
  margin-top: 1vh;
  font-size: 200%;
  margin-bottom: 4vh;
}

h3 {
  font-weight: 100;
  margin-top: 1vh;
  font-size: 150%;
  margin-bottom: 3vh;
}
</style>
