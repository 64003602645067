import { db } from '@/firebase/config'
import wrapWithCache from '@/composables/wrapWithCache'

const getFAQ = async (store) => {
  try {
    store.value = [[],[],[],[]];
    await db.collection("FAQ").orderBy("rank").get().then(docs => {
      docs.forEach(doc => {
        switch (doc.data().category) {
          case "general":
            store.value[0].push({ ...doc.data(), id: doc.id });
            break;
          case "students":
            store.value[1].push({ ...doc.data(), id: doc.id });
            break;
          case "alumni":
            store.value[2].push({ ...doc.data(), id: doc.id });
            break;
          case "WishList":
            store.value[3].push({ ...doc.data(), id: doc.id });
            break;
        }
      })
    })
    // console.log("getFAQ activated");
  } catch (error) {
    alert("getFAQ: " + error);
  }

};

export default wrapWithCache(getFAQ, "FAQ");
